/* eslint-disable react/jsx-no-target-blank */
import { useContext, useEffect, useState } from 'react';
import { AllDigital, Button, DigitalAdvantages, JsonLd, Newsletter, OnlineServices, QuestionsService, ServiceSteps } from '../../components';
import { AppContext } from '../../context/AppContext';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Constancy: React.FC = () => {
  const {
    setModalOpen,
    modalOpen
  } = useContext(AppContext);

  const [imageModal, setImageModal] = useState<'fundaments' | 'beneficts' | ''>('');

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Constancia de conformidad';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/constancy",
            "url": "https://www.cecsatrade.mx/constancy",
            "name": "Constancia",
            "description": "Servicio emitido a importadores: Cuando quieran cumplir con el etiquetado antes del despacho aduanero, es requisito presentar la mercancía ya etiquetada.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/img/icons/constancy.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Documento que respalda que tú producto se encuentre correctamente etiquetado. Evita multas y sanciones en la aduana"
        />
      </Helmet>
      <section className='constancy'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1>CONSTANCIA DE CONFORMIDAD</h1>
        <img src="/img/constancy-image.png" alt="constancy" className='constancy__header-image' />
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Para importadores y fabricantes nacionles</h2>
            <br />
            <p>
              Evita multas y sanciones en la aduana. Obtén este documento que respalde que tus  productos se encuentran correctamente etiquetado.
            </p>
          </div>
        </div>
        <Button
          text={'Solicitar cotización'}
          onClick={() => setModalOpen(true)}
          type={'black'}
          className='constancy__header-button'
        />
        <br />
        <div className='constancy__info'>
          <div className='constancy__info__info-container'>
            <div className='constancy__info__text'>
              <h2>CONSTANCIA</h2>
              <p>
                La <b>constancia de conformidad</b> es un documento esencial que se emite a productores, fabricantes, importadores, comercializadores o prestadores de servicios. <b>Es el resultado de una evaluación rigurosa realizada a la etiqueta de un producto</b>, verificando si cumple o no con los requisitos de las NOM.
                <br />
                Esta evaluación sigue el procedimiento establecido por la <b>Secretaría de Economía en el Acuerdo de reglas y criterios generales de Comercio Exterior</b>, asegurando que cada etiqueta respete los estándares necesarios para su <b>comercialización en México</b>.
              </p>
            </div>
            <div className='constancy__info__image'>
              <img src="/img/importation.png" alt="importation" />
            </div>
          </div>
        </div>
        <div className='constancy__list'>
          <h2>Elige Constancia y obtén...</h2>
          <div className='constancy__list__container'>
            <div className='constancy__list__container__item'>
              <p>En <b>CECSA</b> ya no tendrás que preocuparte por multas y retrasos en la aduana. Tus mercancías podrán cruzar libremente a México evitando demoras por algún incumplimiento de etiquetado. </p>
              <img src="/img/charging.svg" alt="charging" />
            </div>
            <div className='constancy__list__container__item'>
              <img src="/img/documents-check.svg" alt="documents-check" />
              <p>Etiqueta correctamente tus mercancías con base a las <a href="https://blog.cecsatrade.mx/normas-oficiales-mexicanas-vs-normas-mexicanas-conoces-la-diferencia/" target='_blank'>Normas Oficiales Mexicanas</a> antes de que tu proveedor las mande a la aduana y evita asi posibles retrasos.
              </p>
            </div>
          </div>
          <p className='constancy__list__text'>
            <h3>Ideal para:</h3>
            <ul className='constancy__list__text__list'>
              <li><img src="/img/list-dot.svg" alt="list-dot" />Importadores que quieren contar con su empaque especial para México.</li>
              <li><img src="/img/list-dot.svg" alt="list-dot" />Importadores que van a colocar una etiqueta en su producto previo despacho.</li>
              <li><img src="/img/list-dot.svg" alt="list-dot" />Fabricantes nacionales  que quieren sacar su producto al mercado cumpliendo las normas de etiquetado desde el inicio.</li>
            </ul>
            <div className='constancy__list__text__buttons'>
              <Button
                text={'Fundamento legal'}
                onClick={() => setImageModal('fundaments')}
                type={'black'}
                className='constancy__header-button'
              />
              <Button
                text={'Preguntas frecuentes'}
                onClick={() => {
                  const element = document.getElementById("preguntas-frecuentes");

                  if (element) {
                    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                  }
                }}
                type={'black'}
                className='constancy__header-button'
              />
              <Button
                text={'Conoce los beneficios'}
                onClick={() => setImageModal('beneficts')}
                type={'black'}
                className='constancy__header-button'
              />
            </div>
          </p>
        </div>
        {
          imageModal !== '' && (
            <div className='image-modal' onClick={() => setImageModal('')}>
              {/* <div className='image-modal__background' onClick={() => setImageModal('')}></div> */}
              <Button
                text={'Cerrar'}
                onClick={() => setImageModal('')}
                type={'ligth'}
                icon='close.svg'
                className='image-modal__close-button'
                iconClassName='image-modal__close-button__close-icon'
              />
              <img src={`/img/constancy-${imageModal}.png`} alt={imageModal} />
            </div>
          )
        }
        <div className='constancy__legal'>
          <h2 style={{ width: '100%', textAlign: 'center' }}>CECSA te ofrece...</h2>
          <div className='constancy__legal__section-2'>
            <div className='constancy__legal__section-2__item'>
              <img src="/img/legal-1.svg" alt="legal-1" />
              <h3>Presencia Nacional</h3>
              <p>Contamos con un equipo en todo México listo para apoyarte con inspecciones de etiquetado.</p>
            </div>
            <div className='constancy__legal__section-2__item'>
              <img src="/img/legal-2.svg" alt="legal-2" />
              <h3>Trabajamos con tu agente aduanal</h3>
              <p>Facilita el trabajo y gestión de los requisitos que te solicita la aduana.</p>
            </div>
            <div className='constancy__legal__section-2__item'>
              <img src="/img/legal-3.svg" alt="legal-3" />
              <h3>Acreditación y Aprobación</h3>
              <p>En CECSA, contamos con las licencias necesarias según la Ley de Infraestructura de la Calidad. ¡Somos experiencia!
              </p>
            </div>
          </div>
        </div>
        <AllDigital />
        <DigitalAdvantages />
        <OnlineServices />
        {/* <div className='constancy__fundament'>
          <h2>Fundamentos</h2>
          <p className='constancy__fundament__text'>
            ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.
            <br />
            <br />
            2.4.8 Para las mercancías que se listan en el numeral 3 del Anexo 2.4.1 del presente ordenamiento, únicamente se exigirá que las etiquetas o los medios adheribles permitidos, contengan la información establecida en la NOM correspondiente, y que al momento de su introducción al territorio nacional se encuentren adheridas, pegadas, cosidas, colgadas o colocadas en las mercancías como se establezca en cada una de las normas, de tal modo que impida su desprendimiento inmediato, y asegure su permanencia en las mismas hasta llegar al usuario.
            <br />
            <br />
            I. Presentar a despacho aduanero, acompañadas del documento original o copia de la constancia de conformidad expedida por una unidad de verificación o de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad
            <br />
            <br />
            <b>Constancia</b> <br />
            Documento que se emite a los productores, fabricantes, importadores, comercializadores o prestadores de servicios como resultado de la evaluación de la conformidad realizada a una etiqueta en el que se evidencia el cumplimiento o no cumplimiento de los requisitos establecidos en las Normas Oficiales Mexicanas, cuando sea aplicable de conformidad con el procedimiento establecido en el Acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de Comercio Exterior.

          </p>
          <div className='constancy__fundament__image-container'>
            <img src="/img/fundament.svg" alt="Basis" className='constancy__fundament__image-container__image' />
          </div>
        </div> */}
        <ServiceSteps
          withNewsLetter={false}
        />
        <div className={'benefits-organisms benefits-organisms--blue'}>
          <h2>ORGANISMOS INVOLUCRADOS</h2>
          <div className='benefits-organisms__conteiner'>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/ema.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/dgn.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/SE.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/PROFECO.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/SALUD.svg" alt="map location" />
            </div>
          </div>
        </div>
        <QuestionsService
          questions={[
            {
              question: '¿Qué requieres para tramitar la solicitud de servicios?',
              response: <>Primeramente, debes contar con el contrato de servicios firmado.
                <br /><br />Requieres contar con la proforma del pedimento, las facturas comerciales que ampare la mercancía y el llenado de un Lay Out.
                <br /><br />Todo lo anterior, se puede hacer en línea o por correo con alguno de nuestros asesores.</>
            },
            {
              question: '¿Cuánto cuesta tramitar una solicitud de servicios?',
              response: <>Dependerá del número de modelos, partidas que tenga tu pedimento y de las normas que requieras. Con base en la información anterior te entregaremos un presupuesto.<br />Solicita una cotización con uno de nuestros asesores.</>
            },
            {
              question: '¿Cuánto tiempo se requiere tramitar una solicitud de servicios?',
              response: <>Una vez que subes tu información al portal, deberás de ser validada la información por algunos de nuestros asesores. Una vez que la información esta validada tendrás tu solicitud de servicios. El tiempo máximo de espera es de 30 minutos.</>
            },
            {
              question: '¿En cuánto tiempo se validan mis folios?',
              response: <>Es importante mencionar que si tramitas tu servicio antes de la 1:00 pm tendrás validados tus folios ese mismo día.<br /><br />Por caso contrario, en caso de que tramite tu servicio después de la 1:00 pm tus folios saldrán validados al día siguiente.</>
            },
            {
              question: '¿Dónde puedo consultar mis folios validados?',
              response: <>Puede hacerlo a través de dos medios:
                <br /><br />1) En el portal de CECSA, ubicando su número de pedimento a través de la liga <a href="/" target='_blank'>https://app.cecsatrade.mx</a>
                <br /><br />2) En el siguiente portal del SAT <a href="https://aplicacionesc.mat.sat.gob.mx/SOIANET/oia_wfConsultaRapNoms.aspx" target='_blank'>https://aplicacionesc.mat.sat.gob.mx/SOIANET/oia_wfConsultaRapNoms.aspx</a><br /><br />3) O preguntando con alguno de nuestros asesores vía WhatsApp</>
            },
            {
              question: '¿Cuánto tiempo tengo para desaduanar mis mercancías?',
              response: <>Una vez que salgan validados tus folios, cuentas con 30 días para desaduanar y usar los folios que te proporcionaron en CECSA.En caso de que la importación se demore más de 30 días, deberás solicitar nuevamente folios.</>
            },
            {
              question: '¿Debo tener un contrato con un almacén y otro con CECSA?',
              response: <>Si, es necesario que cuentes con un contrato de prestación de servicios tanto con un Almacén General de Depósito y con CECSA. De esta manera podrás importar tus mercancías sin tener tantos retrasos.
                <br /><br />Te sugerimos hacer todo esto previo a que llegue la mercancía a la aduana o incluso desde antes de comprarla. En comercio exterior es mejor ver todo con tiempo.</>
            },
            {
              question: '¿Por qué no puedo trasladar mis mercancías a mi propio almacén?',
              response: <>Para poder llevar las mercancías a tu propio almacén deberás de contar con estos dos requisitos:<br /><br />1) Tener al menos 2 años en el padrón de importadores<br /><br />2) Haber importado al menos 100,000 USD en 1 o varias operaciones en los últimos 12 meses.<br /><br />Esto de conformidad con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.<br /><br />Puedes pedir asesoría con tu agente aduanal o con personal de CECSA para orientarte.</>
            },
            {
              question: '¿Qué tipo de régimen y clave debe tener mi pedimento?',
              response: <>El pedimento de importación deberá de contener la Clave A4 con el Régimen DFIDEPÓSITO FISCAL de conformidad con el Anexo 22 de las reglas generales de comercio exterior.</>
            },
            {
              question: '¿Qué identificador debe tener mi pedimento  a nivel partida?',
              response: <>El identificador a nivel partida que debe de tener los productos sujetos a cumplimiento de normas oficiales mexicanas es PA el cual significa CUMPLIMIENTO DE LA NORMA OFICIAL MEXICANA, PARA VERIFICARSE EN UN ALMACÉN GENERAL DE DEPÓSITO AUTORIZADO de conformidad con el Anexo 22 de las reglas generales de comercio exterior.</>
            },
            {
              question: '¿Mi agente aduanal me puede orientar en esté trámite?',
              response: <>Si, recuerda que ellos son tus representantes y los responsables de clasificar tus mercancías y orientarte en todo el proceso de importación.<br /><br />También puedes pedir ayuda a CECSA</>
            },
            {
              question: '¿CECSA puede liberar mi mercancía de almacén fiscal?',
              response: <>Si, ese es nuestro trabajo, pero primero necesitamos hacer la visita de inspección para corroborar que tus mercancías se encuentren etiquetadas.<br /><br />Una vez hecho lo anterior, te emitiremos el dictamen de cumplimiento, el cual deberás de entregar al Almacén General de Deposito para que puedas solicitar la extracción de tus mercancías.</>
            },
            {
              question: '¿Cuándo se emite el dictamen de cumplimiento?',
              response: <>El dictamen de cumplimiento se emite una vez que hayamos hecho la visita de inspección y corroborado que la totalidad de tus mercancías se encuentran bien etiquetadas.<br /><br />Recuerda que esto es indispensable para que puedas comercializarlas.<br /><br />Recuerda que una vez etiquetadas deberás de solicitar el pedimento de extracción y pagar tus impuestos al comercio exterior.</>
            },
            {
              question: '¿Cuento con un periodo de tiempo específico para hacer el etiquetado?',
              response: <>Si, cuentas con un periodo máximo de 30 dias una vez desaduanadas las mercancías para dar cumplimiento con el etiquetado.<br /><br />En caso de no cumplir con el etiquetado en los 30 dias posteriores al desaduanamiento se emitirá una Negación de Dictamen y perderás el derecho a usar estar alternativa de etiquetado en los próximos 12 meses.<br /><br />Por lo que es de suma importación que se de cumplimiento con el etiquetado en el periodo establecido.<br /><br />De conformidad con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior:<br /><br />En caso en que se detecte el incumplimiento de etiquetado en territorio nacional conforme lo dispuesto en las fracciones II (etiquetado en un almacén fiscal) y III (etiquetado en un almacén particular) del presente numeral, las empresas no podrán acogerse a lo previsto en las mismas por un periodo de 12 meses, contado a partir de que sea detectado, sin perjuicio de las demás disposiciones aplicables.</>
            },
            {
              question: '¿Requiero hacer este proceso para cada importación?',
              response: <>Si, cada vez que hagas una importación es requerido tramitar nuevamente una solicitud de servicios, ya que el dictamen solo cubre la importación para la cual fue tramitada.</>
            }
          ]}
        />
        <Newsletter />
      </section>
    </>
  )
}

export default Constancy