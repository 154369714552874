import { useNavigate } from 'react-router-dom';
import './styles.scss';

const ExcusiveAttention = () => {
  const navigate = useNavigate();
  return (
    <div className='excusive-attention'>
      <h3>DISFRUTA DE UNA ATENCIÓN EXCLUSIVA PARA TÍ...</h3>
      <div className='excusive-attention__container'>
        <div className='excusive-attention__container__item'>
          <span>Infórmate y asegura tus pasos con nosotros</span>
          <button >Asesoría gratuita</button>
        </div>
        <div className='excusive-attention__container__item'>
          <span>Revisa la etiqueta de tus productos para no caer en una sanción</span>
          <button onClick={() => {
            navigate('/revision-service')
          }}>Revisión de etiquetado</button>
        </div>
        <div className='excusive-attention__container__item'>
          <span>Certifica tus productos antes de llegar a la aduana</span>
          <button onClick={() => {
            navigate('/constancy')
          }}>Constancia de conformidad</button>
        </div>
        <div className='excusive-attention__container__item'>
          <span>Certifica tus productos si deseas hacerlo en almacén fiscal</span>
          <button onClick={() => {
            navigate('/tax-warehouse')
          }}>Dictamen de cumplimiento</button>
        </div>
      </div>
    </div>
  )
}

export default ExcusiveAttention