import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { Layout } from './layouts';
import {
  Constancy,
  Main,
  Nom003,
  Nom004,
  Nom015,
  Nom020,
  Nom024,
  Nom050,
  Nom051,
  Nom055,
  Nom139,
  Nom141,
  Nom142,
  Nom173,
  Nom187,
  Nom189,
  Nom235,
  FiscalAddress,
  PrivateWarehouse,
  Revision,
  ComplainstAndAppeals,
  NoticeOfConfidentiality,
  Dictum
} from './views';
import {
  Error404
} from './components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  useEffect(() => {
    setTimeout(
      () => {
        const pElement = Array.from(document.querySelectorAll('p')).find(el => el && el.textContent ? el.textContent.trim() === "Contáctanos hoy y recibe asesoría personalizada" : false);

        if (pElement) {
          const parentElement = pElement.parentElement;

          if (parentElement) {
            parentElement.style.display = "none";
          }
        }
      }, 1000);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Main />} />
          <Route path='nom-003-ssa1-2006' element={<Nom003 />} />
          <Route path='nom-004-se-2021' element={<Nom004 />} />
          <Route path='nom-015-scfi-2007' element={<Nom015 />} />
          <Route path='nom-020-scfi-1997' element={<Nom020 />} />
          <Route path='nom-024-scfi-2013' element={<Nom024 />} />
          <Route path='nom-050-scfi-2004' element={<Nom050 />} />
          <Route path='nom-051-scfi-ssa1-2010' element={<Nom051 />} />
          {/* <Route path='nom-055-scfi-1994' element={<Nom055 />} /> */}
          {/* <Route path='nom-139-scfi-2012' element={<Nom139 />} /> */}
          <Route path='nom-141-ssa1-scfi-2012' element={<Nom141 />} />
          <Route path='nom-142-scfi-ssa1-2014' element={<Nom142 />} />
          <Route path='nom-173-se-2021' element={<Nom173 />} />
          <Route path='nom-187-ssa1-scfi-2002' element={<Nom187 />} />
          <Route path='nom-189-ssa1-scfi-2018' element={<Nom189 />} />
          <Route path='nom-235-se-2020' element={<Nom235 />} />
          <Route path='constancy' element={<Constancy />} />
          <Route path='dictum-service' element={<Dictum />} />
          <Route path='tax-warehouse' element={<FiscalAddress />} />
          <Route path='private-warehouse' element={<PrivateWarehouse />} />
          <Route path='revision-service' element={<Revision />} />
          <Route path='complaints_and_appeals' element={<ComplainstAndAppeals />} />
          <Route path='notice_of_confidentiality' element={<NoticeOfConfidentiality />} />
          {/* <Route path='blog' element={<Blog />} /> */}
          <Route path='*' element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
